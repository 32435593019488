const SHOW_RECENT_PROJECTS_KEY = "SHOW_RECENT_PROJECTS_KEY"
function getShowRecentProjectsValue() {
    return window.sessionStorage.getItem(SHOW_RECENT_PROJECTS_KEY) ?? false
}

function saveShowRecentProjectsValue(token) {
    window.sessionStorage.setItem(SHOW_RECENT_PROJECTS_KEY, token)
}

const preferenceStorage = {
    getShowRecentProjectsValue,
    saveShowRecentProjectsValue,
}

export default preferenceStorage