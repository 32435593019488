import {useEffect, useState} from "react";
import {usePopper} from "react-popper";

function Popup({children, placement = "bottom"}) {
    const [isOpen, setIsOpen] = useState(false);
    const [referenceElement, setReferenceElement] = useState(null)
    const [popperElement, setPopperElement] = useState(null)
    const {styles, attributes} = usePopper(referenceElement, popperElement, {
        placement,
        modifiers: [
            {
                name: 'offset',
                options: {
                    offset: [0, 5],
                },
            }
        ]
    })
    useEffect(() => {
        if (!referenceElement) {
            return
        }
        const cb = (e) => {
            e.stopPropagation()
            setIsOpen(!isOpen)
        }
        const cb2 = () => {
            setIsOpen(false)
        }
        referenceElement.addEventListener('click', cb)
        document.addEventListener('click', cb2)
        return () => {
            referenceElement.removeEventListener('click', cb)
            document.removeEventListener('click', cb2)
        }
    }, [isOpen, referenceElement])
    return children({popperElement, setPopperElement, referenceElement, setReferenceElement, styles, attributes, isOpen})
}

export default Popup