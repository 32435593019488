import './ChangePin.css'
import {useCallback, useState} from "react"
import authStorage from "./auth_storage"
import {redirect, useNavigate} from "react-router-dom"
import Keypad from "./Keypad";

function ChangePin({showMessage, clearMessage}) {
    const [working, setWorking] = useState(false)
    const [pin, setPin] = useState("")
    const [firstPin, setFirstPin] = useState("")
    const [i, setI] = useState(0)
    const navigate = useNavigate()
    const reset = () => {
        setI(0)
        setPin("")
        setFirstPin("")
        setWorking(false)
    }
    const changePin = useCallback(async (pinCode) => {
        try {
            const resp = await fetch('/api/v1/pin-code', {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    'X-Auth-Token': authStorage.get(),
                },
                body: JSON.stringify({
                    pinCode,
                })
            })
            if (!resp.ok) {
                if (resp.status === 401) {
                    reset()
                    authStorage.clear()
                    clearMessage()
                    navigate("/login")
                    return
                }
                const body = await resp.json()
                showMessage(body?.errors[0].message || 'error')
                return
            }
            clearMessage()
            navigate('/')
        } catch (e) {
            console.error(e)
            showMessage(e.toString())
        }
    }, [navigate, showMessage])

    const callback = useCallback(async (newPin) => {
        if (i === 0) {
            setI(1)
            setPin("")
            setFirstPin(newPin)
        } else {
            try {
                if (firstPin !== newPin) {
                    reset()
                    showMessage("PIN codes doesn't match")
                    return
                }
                setWorking(true)
                await changePin(newPin)
            } catch (e) {
                console.error(e)
                showMessage(e.toString())
            } finally {
                reset()
            }
        }
    }, [changePin, firstPin, i, showMessage])

    return (
        <div className="container-fluid d-flex justify-content-center align-items-center vh-100">
            <div className="row">
                <div className="col-12">
                    <div className="vstack">
                        <h1 className="text-center mb-4">{i === 0 ? "Gib eine neue PIN ein" : "Neue PIN erneut eingeben"}</h1>
                        <div className=" d-flex justify-content-center">
                            <Keypad disabled={working} pin={pin} setPin={setPin} callback={callback}/>
                        </div>

                        <a className="btn btn-danger mt-4 p-2 fs-3 m-auto cancel-btn" href="/">Cancel</a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export async function loader() {
    const token = authStorage.get()
    if (!token) {
        return redirect('/login')
    }
    return {}
}

export default ChangePin