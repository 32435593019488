import './Keypad.css'
import {useCallback, useEffect} from "react";

function Keypad({pin, setPin, callback, disabled, showMessage}) {
    const onKeypadClick = useCallback(async (number) => {
        try {
            if (disabled) {
                return
            }
            if (number.length !== 1) {
                console.error(`number should have length 1: ${number}`)
                return
            }
            const newPin = pin + number
            setPin(newPin)
            if (newPin.length === 4) {
                await callback(newPin)
            }
        } catch (e) {
            console.error(e)
            showMessage(e.toString())
        }
    }, [pin, setPin, callback, disabled, showMessage])

    useEffect(() => {
        const cb = async e => {
            if (!['0', '1', '2', '3', '4', '5', '6', '7', '8', '9',].includes(e.key)) {
                return
            }
            await onKeypadClick(e.key)
        }
        document.addEventListener("keydown", cb)
        return () => {
            document.removeEventListener("keydown", cb)
        }
    }, [onKeypadClick])

    return (
        <div className="pin-container text-center">
            <div className="hstack g-3 d-flex justify-content-center">
                <div className="pin-digit mx-2">{pin.length >= 1 ? pin[0] : ""}</div>
                <div className="pin-digit mx-2">{pin.length >= 2 ? pin[1] : ""}</div>
                <div className="pin-digit mx-2">{pin.length >= 3 ? pin[2] : ""}</div>
                <div className="pin-digit mx-2">{pin.length >= 4 ? pin[3] : ""}</div>
            </div>
            <div className="keypad">
                <button className="key m-auto" disabled={disabled} onMouseDown={() => onKeypadClick("1")}>1</button>
                <button className="key m-auto" disabled={disabled} onMouseDown={() => onKeypadClick("2")}>2</button>
                <button className="key m-auto" disabled={disabled} onMouseDown={() => onKeypadClick("3")}>3</button>
                <button className="key m-auto" disabled={disabled} onMouseDown={() => onKeypadClick("4")}>4</button>
                <button className="key m-auto" disabled={disabled} onMouseDown={() => onKeypadClick("5")}>5</button>
                <button className="key m-auto" disabled={disabled} onMouseDown={() => onKeypadClick("6")}>6</button>
                <button className="key m-auto" disabled={disabled} onMouseDown={() => onKeypadClick("7")}>7</button>
                <button className="key m-auto" disabled={disabled} onMouseDown={() => onKeypadClick("8")}>8</button>
                <button className="key m-auto" disabled={disabled} onMouseDown={() => onKeypadClick("9")}>9</button>
                <button className="key zero m-auto" disabled={disabled} onMouseDown={() => onKeypadClick("0")}>0</button>
            </div>
        </div>
    )
}

export default Keypad