import {useCallback, useState} from "react"
import authStorage from "./auth_storage"
import {useNavigate} from "react-router-dom"
import Keypad from "./Keypad";

function Login({showMessage, clearMessage}) {
    const [working, setWorking] = useState(false)
    const [pin, setPin] = useState("")
    const navigate = useNavigate()
    const login = useCallback(async (pinCode) => {
        try {
            const resp = await fetch('/api/v1/authentication', {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    pinCode,
                })
            })
            if (!resp.ok) {
                if (resp.status === 401) {
                    setPin("")
                    showMessage('invalid PIN-Code')
                    return
                }
                const body = await resp.json()
                showMessage(body?.errors[0].message || 'error while logging in')
                return
            }
            const data = await resp.json()
            authStorage.save(data.token)
            clearMessage()
            navigate('/')
        } catch (e) {
            console.error(e)
            showMessage(e.toString())
        }
    }, [navigate])

    const callback = useCallback(async (newPin) => {
        try {
            setWorking(true)
            await login(newPin)
        } catch (e) {
            console.error(e)
            showMessage(e.toString())
        } finally {
            setWorking(false)
        }
    }, [login])

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-12 d-flex justify-content-center align-items-center vh-100">
                    <Keypad disabled={working} pin={pin} setPin={setPin} callback={callback}/>
                </div>
            </div>
        </div>
    )
}

export default Login