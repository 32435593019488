import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import {ReducerProvider} from "./reducer"
import {BrowserRouter,} from "react-router-dom"
import * as Sentry from "@sentry/react";
import "./index.css"

Sentry.init({
    dsn: "https://f3581b0a3131f4f3137d76c2275b57da@o4506017577238528.ingest.us.sentry.io/4507775277334528",
    integrations: [
        Sentry.replayIntegration({
            maskAllText: false,
            blockAllMedia: false,
        }),
    ],
    // Session Replay
    replaysSessionSampleRate: 0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
    <React.StrictMode>
        <ReducerProvider>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </ReducerProvider>
    </React.StrictMode>
)