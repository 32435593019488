const AUTH_TOKEN_KEY = "AUTH_TOKEN"
function get() {
    return window.sessionStorage.getItem(AUTH_TOKEN_KEY)
}

function save(token) {
    window.sessionStorage.setItem(AUTH_TOKEN_KEY, token)
}

function clear() {
    window.sessionStorage.removeItem(AUTH_TOKEN_KEY)
}

const authStorage = {
    get,
    save,
    clear,
}

export default authStorage