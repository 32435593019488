import {createContext, useReducer} from "react";

export const SET_PROJECT_ID = "SET_PROJECT_ID";
export const SET_PROJECTS = "SET_PROJECTS";
export const SET_RECENT_PROJECTS = "SET_RECENT_PROJECTS";
export const SET_PROJECT_DELTA_DURATION = "SET_PROJECT_DELTA_DURATION"
export const INCREASE_PROJECT_DURATION = "INCREASE_PROJECT_DURATION";
export const SET_SEARCH = "SET_SEARCH"
export const SET_STATUS = "SET_STATUS"

function reducer(state, action) {
    switch (action.type) {
        case SET_PROJECT_ID:
            return {...state, projectId: action.payload}
        case SET_PROJECTS:
            return {...state, projects: action.payload}
        case SET_RECENT_PROJECTS:
            return {...state, recentProjects: action.payload}
        case INCREASE_PROJECT_DURATION:
            return {
                ...state,
                projects: state.projects.map(project => {
                    if (project.id !== action.payload.id) {
                        return {...project}
                    } else {
                        return {...project, duration: project.duration + action.payload.duration}
                    }
                })
            }
        case SET_PROJECT_DELTA_DURATION:
            return {
                ...state,
                projectsDeltaDurations: {
                    ...state.projectsDeltaDurations,
                    [action.payload.id]: action.payload.duration
                }
            }
        case SET_SEARCH:
            return {
                ...state,
                search: action.payload,
            }
        case SET_STATUS:
            return {...state, status: action.payload}
        default:
            throw new Error(`Unknown action type: ${action.type}`)
    }
}

export const ReducerContext = createContext(null)
export const ReducerProvider = ({children}) => {
    const [state, dispatch] = useReducer(reducer, window.initState)

    return (
        <ReducerContext.Provider
            value={{state, dispatch}}
        >
            {children}
        </ReducerContext.Provider>
    );
}

export default reducer