export class UnauthorizedError extends Error {
}

export const SESSION_TYPE_CLEANING = 'CLEANING'
export const SESSION_TYPE_CALLING = 'CALLING'
export const SESSION_TYPE_TECHNIK = 'TECHNIK'
export const SESSION_TYPE_NEW_LEAD = 'NEW_LEAD'
export const SESSION_TYPE_PROJECT = 'PROJECT'

export const startSession = async (authToken, type, projectId) => {
    const resp = await fetch('/api/v1/time-tracking/start-session', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'X-Auth-Token': authToken,
        },
        body: JSON.stringify({
            projectId,
            type,
        })
    })
    if (![200, 400, 401].includes(resp.status)) {
        throw new Error(`none ok response: ${resp.status}`)
    }
    return await resp.json()
}

export const stopSession = async (authToken, sessionId) => {
    const resp = await fetch('/api/v1/time-tracking/stop-session', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'X-Auth-Token': authToken,
        },
        body: JSON.stringify({
            sessionId,
        })
    })
    if (![200, 400, 401].includes(resp.status)) {
        throw new Error(`none ok response: ${resp.status}`)
    }
    return await resp.json()
}

export const getData = async (authToken) => {
    const resp = await fetch('/api/v1/time-tracking/status', {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'X-Auth-Token': authToken,
        },
    })
    if (![200, 400, 401].includes(resp.status)) {
        throw new Error(`none ok response: ${resp.status}`)
    }
    return await resp.json()
}

export const getProjects = async (authToken, isOwnProject, isSevenDays, skip = 0, take = 25, search = "", showRecentProjects = false) => {
    let query = {
        skip,
        take,
        search,
    }
    if (isOwnProject) {
        query['own-projects'] = ""
    }
    if (isSevenDays) {
        query['seven-days'] = ""
    }
    if (showRecentProjects) {
        query['show-recent-projects'] = ""
    }
    const resp = await fetch(`/api/v1/projects?${serialize(query)}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'X-Auth-Token': authToken,
        },
    })
    if (!resp.ok) {
        if (resp.status === 401) {
            throw new UnauthorizedError()
        }
        throw new Error(`none ok response: ${resp.status}`)
    }
    return (await resp.json()).payload
}

const serialize = function (obj) {
    const str = [];
    for (const p in obj)
        if (obj.hasOwnProperty(p)) {
            str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
        }
    return str.join("&");
}

export const toHMS = secondsHMS => {
    const hours = Math.floor(secondsHMS / (60 * 60))
    const secondsMS = Math.floor(secondsHMS % (60 * 60))
    const seconds = Math.floor(secondsMS % 60)
    const minutes = Math.floor(secondsMS / 60)
    return {
        minutes,
        seconds,
        hours,
    }
}

export const calcSessionsTimes = sessions => {
    let workTime = 0
    let breakTime = 0
    if (!sessions.length) {
        return {
            workTime: 0,
            breakTime: 0,
        }
    }
    for (const session of sessions) {
        let end = new Date(session.endTimestamp).getTime()
        if (end === 0) {
            end = new Date().getTime()
        }
        if (session.type === 'BREAK') {
            breakTime += end - new Date(session.startTimestamp).getTime()
        } else {
            workTime += end - new Date(session.startTimestamp).getTime()
        }
    }
    return {
        workTime: Math.floor(workTime / 1000),
        breakTime: Math.floor(breakTime / 1000),
    }
}

export const calcStampsTimes = stamps => {
    let workTime = 0
    let breakTime = 0
    if (!stamps.length) {
        return {
            workTime: 0,
            breakTime: 0,
        }
    }
    for (let i = 0; i < stamps.length - 1; i++) {
        if (stamps[i].timeAccount === 1) {
            workTime += new Date(stamps[i + 1].timestamp).getTime() - new Date(stamps[i].timestamp).getTime()
        }
        if (stamps[i].timeAccount === 2) {
            breakTime += new Date(stamps[i + 1].timestamp).getTime() - new Date(stamps[i].timestamp).getTime()
        }
    }
    if (stamps[stamps.length - 1].timeAccount === 1) {
        workTime += new Date().getTime() - new Date(stamps[stamps.length - 1].timestamp).getTime()
    }
    if (stamps[stamps.length - 1].timeAccount === 2) {
        breakTime += new Date().getTime() - new Date(stamps[stamps.length - 1].timestamp).getTime()
    }
    return {
        workTime: Math.floor(workTime / 1000),
        breakTime: Math.floor(breakTime / 1000),
    }
}