import {
    SESSION_TYPE_CALLING,
    SESSION_TYPE_CLEANING,
    SESSION_TYPE_NEW_LEAD,
    SESSION_TYPE_TECHNIK,
    toHMS
} from "./timeTracking";

function InfoPanel({status}) {
    if (!status) {
        return
    }
    if (status.statusCode === 2) {
        return (
            <div className={"projectInfo mw-100 text-warning"}>
                <p className="m-0 text-truncate">Break</p>
            </div>
        )
    }
    if (!status.session) {
        return null
    }
    if (status.statusCode === 1) {
        if (status.session.type === 'PROJECT') {
            const project = status.session.Project
            const {hours, minutes, seconds} = toHMS(Math.floor((new Date().getTime() - new Date(status.session.startTimestamp).getTime()) / 1000))
            return (
                <div className={"projectInfo mw-100 text-success"}>
                    <p className="m-0 text-truncate text-center">{hours < 10 ? '0' : ""}{hours.toString()}:{minutes < 10 ? '0' : ""}{minutes.toString()}:{seconds < 10 ? '0' : ""}{seconds.toString()}</p>
                    <p className="m-0 text-truncate text-center">{`#${project.number} ${project.name}`}</p>
                </div>
            )
        } else {
            let typeText = ""
            switch (status.session.type) {
                case SESSION_TYPE_CLEANING:
                    typeText = "Cleaning"
                    break
                case SESSION_TYPE_CALLING:
                    typeText = "Calling"
                    break
                case SESSION_TYPE_TECHNIK:
                    typeText = "TechniK"
                    break
                case SESSION_TYPE_NEW_LEAD:
                    typeText = "New lead"
                    break
                default:
                    throw new Error(`unknown type session type: ${status.session.type}`)
            }
            const {hours, minutes, seconds} = toHMS(Math.floor((new Date().getTime() - new Date(status.session.startTimestamp).getTime()) / 1000))
            return (
                <div className={"projectInfo mw-100 text-success"}>
                    <p className="m-0 text-truncate text-center">{hours < 10 ? '0' : ""}{hours.toString()}:{minutes < 10 ? '0' : ""}{minutes.toString()}:{seconds < 10 ? '0' : ""}{seconds.toString()}</p>
                    <p className="m-0 text-truncate text-center">{typeText}</p>
                </div>
            )
        }
    }
    return null
}

export default InfoPanel