import {calcStampsTimes, toHMS} from "./timeTracking";
import {useInterval} from "./hooks";


function Time({loading, status, time, setTime}) {
    useInterval(() => {
        if (!status) {
            return
        }
        if (status.statusCode === 1) {
            const {workTime} = calcStampsTimes(status.stamps)
            setTime(workTime)
        }
        if (status.statusCode === 2) {
            const {breakTime} = calcStampsTimes(status.stamps)
            setTime(breakTime)
        }
    }, 100)
    if (loading) {
        return <span className="loader"></span>
    }
    if (!status) {
        return <h1
            className="text-center time text-secondary">00:00:00</h1>
    }
    if (status.statusCode === 1) {
        const wt = toHMS(time)
        return (
            <h1 className="text-center time">{wt.hours < 10 ? '0' : ""}{wt.hours.toString()}:{wt.minutes < 10 ? '0' : ""}{wt.minutes.toString()}:{wt.seconds < 10 ? '0' : ""}{wt.seconds.toString()}</h1>
        )
    }
    if (status.statusCode === 2) {
        const bt = toHMS(time)
        return (
            <div className="vstack gap-1 justify-content-center">
                <h1 className="text-center time text-warning">{bt.hours < 10 ? '0' : ""}{bt.hours.toString()}:{bt.minutes < 10 ? '0' : ""}{bt.minutes.toString()}:{bt.seconds < 10 ? '0' : ""}{bt.seconds.toString()}</h1>
            </div>
        )
    }
    return <h1
        className="text-center time text-secondary">00:00:00</h1>
}

export default Time